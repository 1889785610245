
import React from 'react'
import radarImg from '../../assets/images/BN.png'
import './style.css'

export default () => (
  <div className='banner'>
    <div className='banner-wrapper'>
      <img src={radarImg} className='radar' width="802px" height="422px" />
      <div className="flex-wrapper">
        <div>
          <h1>Experter på</h1>
          <h1>professionell</h1>
          <h1 class="hide-on-mobile">navigationsutrustning</h1>
          <h1 class="show-on-mobile">navigations-</h1>
          <h1 class="show-on-mobile">utrustning</h1>
          <br/>
          <p>
            BN Marinelektronik är idag en av de största leverantörerna av marinelektronik på Ostkusten och arbetar främst mot yrkeskunder såsom Sjöpolisen, Sjöfartsverket, Utö Rederi, Runmarö Båttaxi, Värmdö Skärgårdstransporter, Vattenfall med flera samt ett stort antal privatkunder.
          </p>
        </div>
      </div>
    </div>
  </div>
)
