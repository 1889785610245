
import { flamelinkConnection } from "../flamelinkConnection";

export const getProductPage = (pg, brand) => {
  return (dispatch, getState) => {
    dispatch({ type: 'GET_PRODUCTS_START' })
    const { products } = getState()
    if(products[pg]) {
      if(products[pg][brand]) {
        return dispatch({ type: 'GET_PRODUCTS_CACHED' })
      }
    }

    flamelinkConnection.getPage(pg, brand).then(res => {
      let data = {}
      Object.values(res)[0].categories.map(c => {
        data[c.category.name] = c.products
      })

      return dispatch({ type: 'GET_PRODUCTS_SUCCESS', pg, brand, data })
    }).catch(error => {
      console.log('error', error)
      return dispatch({ type: 'GET_PRODUCTS_FAILED' })
    })
  }
}