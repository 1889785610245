

import React, { Component } from 'react'
import {
  Route,
  withRouter,
} from "react-router-dom";
import { connect } from 'react-redux'
import { getProductPage } from '../../actions/index'
import Loader from '../../components/Loader/index'
import Products from './Products'
import './style.css'
import triangle from '../../assets/icons/menu_triangle.svg'

class ProductPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activetab: props.startTab || 0,
      selectOpen: false,
    }
    this.renderCategorySelect = this.renderCategorySelect.bind(this)
    this.toggleSelectTab = this.toggleSelectTab.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const { productGroup, brand } = this.props.match.params
    this.props.dispatch(getProductPage(productGroup, brand))
  }

  componentDidUpdate(prevProps) {
    const { match, history, location, categories } = this.props
    const { productGroup, brand } = match.params

    if(productGroup === prevProps.match.params.productGroup) {
      if(brand === prevProps.match.params.brand) {
        if (categories.length && location.pathname.split('/').length <= 4) {
          let firstCategory = categories[0]
          history.push(`${location.pathname}/${firstCategory}`)
        }
        return
      } else {
        this.props.dispatch(getProductPage(productGroup, brand))
        window.scrollTo(0, 0)
      }
    } else {
      this.props.dispatch(getProductPage(productGroup, brand))
      window.scrollTo(0, 0)
    }

  }

  toggleTab (category) {
    const { match, history } = this.props
    this.setState({ selectOpen: false }, () => {
      history.push(`${match.url}/${category}`)
    })

  }

  toggleSelectTab () {
    this.setState({ selectOpen: !this.state.selectOpen })
  }

  renderCategoryTab (category, i) {
    const { location } = this.props
    return <div key={i} id={i} className={location.pathname.includes(category) ? 'tab active' : 'tab'} onClick={() => this.toggleTab(category)}>
      <p>{category}</p>
    </div>
  }

  // only for mobile
  renderCategorySelect () {
    const url = this.props.location.pathname.split('/')
    if(url.length < 5) return
    const category = url[4]
    // if(!category) return null
    return <div className={this.state.selectOpen ? 'category-select open' : 'category-select'}  onClick={this.toggleSelectTab}>
      <p>{category}</p>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <p style={{fontSize: 16, color: '#A9A9A9', marginRight: 10}}>kategori</p>
        <img src={triangle} />
      </div>
    </div>
  }

  render() {
    const { categories, match, } = this.props
    const { productGroup, brand, category } = match.params
    if(this.props.loading) {
      return <div className='product-page'>
      <div className='page-wrapper'>
        <h2>{productGroup} från {brand}</h2>
        <Loader />
        </div>
      </div>
    }
    return <div className='product-page'>
      <div className='page-wrapper'>
        <h2>{productGroup} från {brand}</h2>
        {this.renderCategorySelect()}
        <div className={this.state.selectOpen ? 'category-tabs open' : 'category-tabs'} >
          {categories.length ? categories.map( (category, i) => this.renderCategoryTab(category, i)) : null}
        </div>
        <Route path="/products/:productGroup/:brand/:category" component={Products} />
      </div>
    </div>
  }
}

const mapStateToProps = (state, ownProps) => {
  const { productGroup, brand } = ownProps.match.params
  let categories = []
  if(state.products[productGroup]) {
    if(state.products[productGroup][brand]) {
      categories = Object.keys(state.products[productGroup][brand])
    }
  }

  return {
    categories: categories,
    loading: state.loading
  }
}

export default withRouter(connect(mapStateToProps)(ProductPage))
