
import React, { Component } from 'react'
import Banner from './Banner'
import CustomerCategories from './CustomerCategories'
import Resellers from './Resellers'
import './style.css'

export default class HomePage extends Component  {

  render () {
    return <div>
      <Banner />
      <CustomerCategories/>
      <Resellers />
    </div>
  }
}