
import React from 'react'
import Button from '../../components/Button/index'
import { Link } from "react-router-dom"

export default () => (
  <div className="categories">
    <div className="categories-wrapper">
      <div className="flex-wrapper">
        <Link to="/customerCategories/1">
          <div className="category-block">
            <div className="cat-1" />
            <div className="category-name">
              <p>För fritidsbåtar</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="flex-wrapper">
      <Link to="/customerCategories/2">
          <div className="category-block">
            <div className="cat-2" />
              <div className="category-name">
                <p>För yrkesbåtar</p>
              </div>
            </div>
            </Link>
      </div>
      {/* <div className="flex-wrapper">
        <Link to="/customerCategories/3">
          <div className="category-block">
            <div className="cat-3" />
            <div className="category-name">
              <p>För premiumbåtar</p>
            </div>
          </div>
        </Link>
      </div> */}
    </div>
    {/* <Button /> */}
  </div>
)