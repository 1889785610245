import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import Documents from './Documents'
import LinkedProducts from './LinkedProducts'

export default class ProductTab extends Component {
  constructor () {
    super()
    this.state = {
      descriptionOpen: false
    }
    this.toggleDescription = this.toggleDescription.bind(this)
  }

  toggleDescription () {
    this.setState({ descriptionOpen: !this.state.descriptionOpen})
  }

  componentDidUpdate (prevProps) {
    if(prevProps.id !== this.props.id) {
      this.setState({ descriptionOpen: false })
    }
  }

  renderFeatures () {
    return<div style={{ marginTop: 50}}>
      <p className='title'>Viktiga funktioner</p>
      {this.props.importantFeatures.map((f, i) => {
        return <div key={i} className="link-row">
          <div style={{ width: 8, height: 8, borderRadius: 10, backgroundColor: '#FA5755' }}/>
            <p style={{marginLeft: 12}}>{f.text}</p>
        </div>
      })}
    </div>
  }

  render() {
    const { id, videoUrl, images, brand, name, description, documents, linkedProducts, history, importantFeatures} = this.props
    if(!this.props.id) return null
    return <div className='product-model-info'>
      <div className="product-model">
        <div className='wrapper'>
          <div className='img-column'>
            {images.map((image, i) => <div key={i} className="img" style={{backgroundImage: `url(${image.url})`}} />)}
            {videoUrl ? <ReactPlayer url={videoUrl} width={'100%'} height={290}/> : null}
          </div>
          <div className='text-column'>
            <p className='title'>{brand.name} {name}</p>
            <p className={this.state.descriptionOpen ? 'description-open' : 'description-closed'}>{description}</p>
            <p className='read-more' onClick={this.toggleDescription}>{this.state.descriptionOpen ? 'Dölj' : 'Läs mer'}</p>
            {importantFeatures ? this.renderFeatures() : null }
            {documents && documents.length > 0 ? <Documents documents={documents} /> : null}
            {linkedProducts && linkedProducts.length > 0 ? <LinkedProducts linkedProducts={linkedProducts} history={history} /> : null}
          </div>
        </div>
      </div>
    </div>
  }
}