import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'

// Flamelink app is always required
import flamelink from 'flamelink/app'
// Add additional modules that you want to use
import 'flamelink/content'
import 'flamelink/storage'
import 'flamelink/navigation'

const firebaseConfig = {
  apiKey: "AIzaSyCiU7IToKJUKVFix6u4qHe6Hw-0Y8KblcQ",
  authDomain: "bnmarin-website.firebaseapp.com",
  databaseURL: "https://bnmarin-website.firebaseio.com",
  projectId: "bnmarin-website",
  storageBucket: "bnmarin-website.appspot.com",
  messagingSenderId: "265561673334",
  appId: "1:265561673334:web:50e9c4596417d510"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)

const app = flamelink({
  firebaseApp, // required
  dbType: 'cf', // can be either 'rtdb' or 'cf' for Realtime DB or Cloud Firestore
  env: 'production', // optional, default shown
  locale: 'en-US', // optional, default shown
  precache: true // optional, default shown. Currently it only precaches "schemas" for better performance
})

class FlamelinkConnection {

  async getPage (pg, brand) {
    const db = firebase.firestore()
    let qs = await db.collection("fl_content").where("name", "==", pg).limit(1).get()
    let pgRef = qs.docs[0].ref

    qs = await db.collection("fl_content").where("name", "==", brand).limit(1).get()
    let brandRef = qs.docs[0].ref

    return app.content.get({
      schemaKey: 'productPage',
      populate: [
        {
          field: 'categories',
          fields: ['category', 'products'],
          populate: [
            {
              field: 'category',
              fields: ['name']
            },
            {
              field: 'products',
              populate: [
                { field: 'brand' },
                { field: 'productCategory',
                  fields: ['name']
                },
                { field: 'productGroup',
                  fields: ['name'],
                  populate: ['name']
                },
                { field: 'images' },
                {
                  field: 'documents',
                  populate: ['file']
                },
                {
                  field: 'linkedProducts',
                  fields: ['id', 'name', 'description', 'brand', 'productGroup', 'productCategory'],
                  populate: ['brand', 'productGroup', 'productCategory']
                },
              ],
              fields: [
                'id',
                'name',
                'description',
                'brand',
                'productGroup',
                'productCategory',
                'videoUrl',
                'images',
                'documents',
                'linkedProducts',
                'menuPosition',
                'importantFeatures'
              ],
            }
          ]
        },
      ],
      fields: ['productGroup', 'brand', 'categories'],
      filters: [ ['productGroup', '==', pgRef], ['brand', '==', brandRef] ],
    })

  }

  getMenu() {
    return app.content.get({
      schemaKey: 'menu',
      limitToFirst: 1,
      populate: [{
        field: 'rows',
        populate: ['name', 'brands']
      }],
      fields: ['rows']
    })
  }

  getCustomerCategory(id) {
    return app.content.getByField({
      schemaKey: 'customerCategory',
      field: 'category',
      value: id,
      populate: [
        { field: 'image' },
        {
          field: 'productLinks',
          fields: ['id', 'name', 'description', 'brand', 'productGroup', 'productCategory'],
          populate: ['brand', 'productGroup', 'productCategory']
        },

      ],
      fields: [ 'category', 'description', 'image', 'productLinks'],
    })
  }
}

export const flamelinkConnection = new FlamelinkConnection();
