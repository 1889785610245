import React, { Component } from 'react'
import triangle from '../../assets/icons/list_triangle.svg'
import imgPlaceholder from '../../assets/images/picture.png'
import Loader from '../../components/Loader/index'
import Button from '../../components/Button/index'
// import image from '../../assets/images/cat_1_page.png'
import './style.css'
import { flamelinkConnection } from "../../flamelinkConnection";


export default class CustomerCategoryPage extends Component {
  constructor() {
    super()
    this.state = {
      categoryName: {'1': 'fritidsbåtar','2': 'yrkesbåtar','3': 'premiumbåtar'}
    }
    this.renderLinks = this.renderLinks.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillMount() {
    const { categoryId } = this.props.match.params
    flamelinkConnection.getCustomerCategory(categoryId).then( res => {
      const category = res[Object.keys(res)[0]]
      this.setState({
        ...category.description,
        image: category.image[0].url,
        productLinks: category.productLinks
      })
    }).catch(e => console.log('ERROR', e))
  }

  onClick (title, brand, category, id) {
    this.props.history.push(`/products/${title}/${brand}/${category}`, {startId: id})
  }

  renderProductLink(i, p) {
    return <div key={i} className="link-row">
      <img src={triangle} />
      <div onClick={() => this.onClick(p.productGroup.name, p.brand.name, p.productCategory.name, p.id)}>
        <p>{p.name}</p>
      </div>
    </div>
  }

  renderLinks () {
    if(!this.state.productLinks) return null
    const { categoryId } = this.props.match.params
    return <div className="block">
      <p className="title">Populära produkter till {this.state.categoryName[categoryId]}</p>
      {this.state.productLinks.map((p, i) => this.renderProductLink(i, p))}
      {/* <Button /> */}
    </div>
  }

  render () {
    const { categoryId } = this.props.match.params
    const { title, text, image } = this.state
    return <div className="customer-category-page">
    <div className="page-wrapper">
      <h2>Produkter för {this.state.categoryName[categoryId]}</h2>

      <div className="img" style={{backgroundImage: `url(${image})`, backgroundColor: '#D8D8D8'}}>
        {image ? null : <Loader noText backgroundColor='#D8D8D8'/>}
      </div>

      {/* <img className="img" src={image} /> */}
      <div className="flex-wrapper">
        <div className="block">
          <p className="title">{title}</p>
          <p>{text}</p>
        </div>
        {this.renderLinks()}
      </div>
    </div>
  </div>
  }
}