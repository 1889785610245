import React from 'react'
import file from '../../assets/icons/file.svg'

export default ({documents}) => {
  return <div>
    <p className='title docs'>Dokument</p>
    {documents.map( (d, i) => {
      if(d.file.length < 1) return null
      return <div key={i} className="link-row">
        <img src={file} />
        <a href={d.file[0].url} target="_blank">
          <p>{d.name}</p>
        </a>
      </div>
    })}
  </div>
}