
import React, { Component } from 'react'
import { withRouter } from "react-router-dom"
import { connect } from 'react-redux'
import ProductTab from './ProductTab'
import triangle from '../../assets/icons/menu_triangle.svg'

class Products extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activetab: props.startTab || 0,
      selectOpen: false
    }
    this.toggleSelectTab = this.toggleSelectTab.bind(this)
  }

  toggleSelectTab () {
    this.setState({ selectOpen: !this.state.selectOpen })
  }

  toggleTab(i) {
    this.setState({activetab: i, selectOpen: false})
  }

  renderTab (modelName, i) {
    return <div key={i} id={i} className={this.state.activetab === i ? 'tab active' : 'tab'} onClick={() => this.toggleTab(i)}>
      <p>{modelName}</p>
    </div>
  }

  // only for mobile
  renderModelSelect () {
    const product = this.props.products[this.state.activetab]
    if(!product) return null
    return <div className={this.state.selectOpen ? 'model-select open' : 'model-select'}  onClick={this.toggleSelectTab}>
      <p>{product.name}</p>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <p style={{fontSize: 16, color: '#A9A9A9', marginRight: 10}}>produkt</p>
        <img src={triangle} />
      </div>
    </div>
  }

  componentDidUpdate (prevProps) {
    const { location, match, history } = this.props
    if(prevProps.match.params.category !== this.props.match.params.category) {
      if(this.props.startTab && (prevProps.startTab === undefined || this.props.startTab !== prevProps.startTab) ) {
        this.setState({ activetab: this.props.startTab }, () => {
          history.replace(location.pathname, null)
        })
      } else {
        this.setState({ activetab: 0 })
      }
      window.scrollTo(0, 0)
    } else {
      if(this.props.startTab && (prevProps.startTab === undefined || this.props.startTab !== prevProps.startTab)) {
        this.setState({ activetab: this.props.startTab }, () => {
          history.replace(location.pathname, null)
        })
      }
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { match, products, loading } = this.props
    const { category } = match.params
    return <div>
      {this.renderModelSelect()}
      <div className={this.state.selectOpen ? 'model-tabs open' : 'model-tabs'} >
        {products.length ? products.map( (p, i) => this.renderTab(p.name, i)) : null}
      </div>
      <ProductTab {...products[this.state.activetab]} />
    </div>
  }
}


const mapStateToProps = (state, ownProps) => {
  const { productGroup, brand, category } = ownProps.match.params
  let products = []
  if(state.products[productGroup]) {
    if(state.products[productGroup][brand]) {
      if(state.products[productGroup][brand][category]) {
        products = state.products[productGroup][brand][category]
      }
    }
  }

  let startTab = undefined
  if(ownProps.location.state && ownProps.location.state.startId) {
    const { startId } = ownProps.location.state
    startTab = products.map(p => { return p.id }).indexOf(startId);
    startTab = startTab > 0 ? startTab : 0
  }

  return {
    startTab,
    products,
    loading: state.loading
  }
}

export default withRouter(connect(mapStateToProps)(Products))
