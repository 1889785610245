
import React, { Component } from 'react'
import { Link } from "react-router-dom"
import './style.css'
import logo from '../../assets/images/bn_logotype.png'
import mobileMeny from '../../assets/images/mobilmeny.png'
import Menu from './Menu'

export default class Header extends Component {
  constructor () {
    super()
    this.state = {
      menuOpen: false
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu () {
    this.setState({ menuOpen: !this.state.menuOpen})
  }

  render () {
    return <div className='header'>
    <nav>
      <Link to="/">
        <img className='logo' src={logo} height="50" width="214.3" />
      </Link>
      <div className='mobile-icon-wrapper' onClick={this.toggleMenu} >
        <img className='mobile-icon' src={mobileMeny} height="18" width="24" />
      </div>
      <ul>
        <li onClick={this.toggleMenu}>
          Våra Produkter
        </li>
        <li onClick={this.props.scrollTo}>
          Kontakta oss
        </li>
      </ul>
    </nav>
    <Menu show={this.state.menuOpen} history={this.props.history} toggleMenu={this.toggleMenu} scrollTo={this.props.scrollTo} />
  </div>
  }
}
