import React, { Component, createRef } from 'react'
import './App.css'

import { connect } from 'react-redux'
import { BrowserRouter as Router, Route } from "react-router-dom"

import HomePage from './screens/HomePage/index'
import ContactPage from './screens/ContactPage'
import ProductsPage from './screens/ProductPage/index'
import CustomerCategory from './screens/CustomerCategoryPage/index'
import Header from './components/Header/index'
import Footer from './components/Footer/index'

import add from './assets/icons/plus.png'
import closeIcon from './assets/icons/close-outline.svg'

// Detects if device is on iOS
const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test( userAgent );
}
// Detects if device is in standalone mode
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

class App extends Component {
  constructor() {
    super()
    this.state={}
    this.ref = createRef();
    this.scrollTo = this.scrollTo.bind(this)
    this.state = {
      installMsgClosed: false
    }
  }

  scrollTo () {
    this.ref.current.scrollIntoView({ behavior: 'smooth' });
  }

  renderInstallMessage () {
    return <div style={{zIndex: 5, width: '100vw', position: 'fixed', bottom: 5, left: 0, }}>
      <div className='install-wrapper'>
        <div className='install-message-wrapper'>
          <img src={add} className='install-message'/>
        </div>
        <p style={{ fontSize: 14 }}>Install this webapp on your iPhone</p>
        <div className='install-message-close' onClick={() => this.setState({ installMsgClosed: true })}>
          <img src={closeIcon} className='install-message'/>
        </div>
      </div>
      <div className='arrow-down'/>
    </div>
  }

  render () {
    return (
      <Router>
        <Route path="/" component={(props) => (<Header {...props} scrollTo={this.scrollTo}/>)}/>
        <Route exact path="/" component={HomePage}/>
        <Route path="/contact" component={ContactPage}/>
        <Route path="/customerCategories/:categoryId" component={CustomerCategory} />
        <Route path="/products/:productGroup/:brand" component={ProductsPage} />
        <div ref={this.ref}>
          <Footer />
        </div>
        {isIos() && !isInStandaloneMode() && !this.state.installMsgClosed ? this.renderInstallMessage() : null}
      </Router>
    );
  }
}

export default connect()(App);
