
import React from 'react'
import furunoSvg from '../../assets/images/furuno.svg'
import simradSvg from '../../assets/images/simrad.svg'
import empirebusSvg from '../../assets/images/empirbus.svg'

import victronSvg from '../../assets/images/victron_energy.svg'
import transasSvg from '../../assets/images/transas.svg'

import actisenseSvg from '../../assets/images/actisense.svg'
import spcellpowerSvg from '../../assets/images/spcellpower.svg'
import yachtSvg from '../../assets/images/yacht_devices.svg'

import timezone from '../../assets/images/timezone.png'

export default () => (
  <div className="reseller">
    <div className="reseller-wrapper">
      <div className="flex-wrapper">
        <img src={furunoSvg} width='267' height='88'/>
      </div>
      <div className="flex-wrapper">
        <img src={timezone} width='189' height='25' style={{ tintColor: 'red'}}/>
      </div>
      <div className="flex-wrapper">
        <img src={simradSvg} width='148' height='29'/>
      </div>

    </div>

    <div className="reseller-wrapper">
      <div className="flex-wrapper">
        <img src={empirebusSvg} width='247' height='68'/>
      </div>
      <div className="flex-wrapper">
        <img src={victronSvg} width='209' height='40'/>
      </div>
      <div className="flex-wrapper">
        <img src={transasSvg} width='237' height='58'/>
      </div>
    </div>

    <div className="reseller-wrapper">
      <div className="flex-wrapper">
        <img src={actisenseSvg} width='170' height='33'/>
      </div>
      <div className="flex-wrapper">
        <img src={spcellpowerSvg} width='153' height='45'/>
      </div>
      <div className="flex-wrapper">
        <img src={yachtSvg} width='202' height='26'/>
      </div>
    </div>
  </div>
)