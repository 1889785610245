import React from 'react'
import './style.css'
export default () => (
  <footer>
    <div className="footer-wrapper">
      <div className="flex-wrapper">
        <div className="footer-block">
          <p className="title">Kontakta oss</p>
          <p>
            Vi finns i Stockholms skärgård och är verksamma året runt. Vi har tillgång till snabb servicebåt och egen service brygga.
          </p>
          <br/>
          <p>Besöksadress</p>
          <p>Korsholmen, Dalarö</p>
          <br/>
          <p>Postadress</p>
          <p>BN Marin Elektronik AB</p>
          <p>Box 132</p>
          <p>137 25 Dalarö</p>
          <br/>
          <p>Kontakt</p>
          <p>Mobil:   070 595 01 16</p>
          <p>Kontor: 	08 501 515 29</p>
          <p>E-post:  <a className='link' href="mailto:info@bnmarin.se">info@bnmarin.se</a></p>
          <br/>
          <p>Innehar F-skattebevis</p>
          <p>Bankgiro: 5019-8845</p>
          <p>Organisationsnummer: 556461-5077</p>
        </div>
      </div>
      <div className="flex-wrapper">
        <div className="footer-block">
          <p className="title">Hitta oss</p>
          <div className="map"></div>
        </div>
      </div>
    </div>
	</footer>
)