import React from 'react'
import triangle from '../../assets/icons/list_triangle.svg'
import {Link} from 'react-router-dom'

export default ({linkedProducts}) => {
  return <div>
    <p className='title docs'>Kompatibla Produkter</p>
    {linkedProducts.map( (p, i) => {
      if (!p) return null
      if(!p.productGroup) return null
      if(!p.brand) return null
      if(!p.productCategory) return null
      return <div key={i} className="link-row">
        <img src={triangle} />
        <Link to={{
          pathname: `/products/${p.productGroup.name}/${p.brand.name}/${p.productCategory.name}`,
          state: { startId: `${p.id}` }
          }}
        >{p.name}</Link>
      </div>
    })}
  </div>
}