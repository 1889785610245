
const initialState = {
  products: {},
  productPage: {},
  loading: false,
  menu: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_PRODUCTS_START':
      return Object.assign({}, state, {
        loading: true
      })
    case 'GET_PRODUCTS_SUCCESS':
      return Object.assign({}, state, {
        products: {
          ...state.products,
          [`${action.pg}`]: {
            ...state.products[action.pg],
            [`${action.brand}`]: action.data,
          }
        },
        loading: false
      })
    case 'GET_PRODUCTS_CACHED':
      return Object.assign({}, state, {
        loading: false
      })
    case 'GET_PRODUCTS_FAILED':
      return Object.assign({}, state, {

      })
    default:
      return state
  }
}
