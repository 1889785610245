import React, { Component } from 'react'
import triangle from '../../assets/icons/menu_triangle.svg'
import { flamelinkConnection } from "../../flamelinkConnection";

const SubRow = ({onClick, title }) => {
  return <div onClick={onClick} className="under-row" >
      <p>{title}</p>
  </div>
}

class MenuRow extends Component {
  constructor () {
    super()
    this.state = {
      open: false
    }
    this.onToggle = this.onToggle.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  onToggle () {
    this.setState({ open: !this.state.open })
  }

  renderProduct () {
    return <div className="under-row">
      <a href="">
        <p>Brand</p>
      </a>
    </div>
  }

  onClick (id, title, brandID, brand) {
    this.props.toggleMenu()
    this.props.history.push(`/products/${title}/${brand}`)
    // console.log(id, brandID)
  }

  render () {
    const { id, title, children } = this.props
    // console.log('CHILDREN', children)
    return <div className="row" >
      <div className="type" onClick={this.onToggle}>
        <p>{title}</p>
        <img src={triangle} className={this.state.open ? 'open' : null}/>
      </div>
      <div className={this.state.open ? 'sub-menu' : 'sub-menu closed'}>
        {
          children.map( (brand, i) => <SubRow key={i} title={brand.name} onClick={() => this.onClick(id, title, brand.id, brand.name)} />)
        }
      </div>
    </div>
  }
}

export default class Menu extends Component {
  constructor() {
    super()
    this.state = {}
    this.onClickContanctUs = this.onClickContanctUs.bind(this)
  }

  componentWillMount () {
    flamelinkConnection.getMenu().then( res => {
      console.log('RES', res)
      this.setState({ items: Object.values(res)[0].rows })
    })
  }

  onClickContanctUs () {
    this.props.scrollTo()
    this.props.toggleMenu()
  }

  render () {
    if(!this.props.show || !this.state.items) return null

    return <div id="menu" className='menu'>
      {
        this.state.items.map( (productGroup, i) => {
          // console.log(productGroup)
          if(!productGroup.brands || productGroup.brands.length < 1) return
          return <MenuRow
            key={i}
            toggleMenu={this.props.toggleMenu}
            history={this.props.history}
            title={productGroup.name}
            id={productGroup.id}
            // children={Object.keys(this.state.items[productGroup])} products={this.state.items[productGroup]}
            children={productGroup.brands}
            // products={this.state.items[productGroup]}
          />
        })
      }
      <div className='row mobile-contact' onClick={this.onClickContanctUs}>
        <p>Kontakta oss</p>
      </div>
    </div>
  }
}

